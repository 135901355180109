<template>
  <Section
    class="bg-white px-5 md:px-36 lg:px-64 flex justify-start items-center"
    v-smartnav
  >
    <div>
      <h3 class="text-6xl font-bold text-green-700">
        Every Human
        <span class="block text-gray-800">Has Rights</span>
      </h3>
      <br />
      <p class="text-gray-700">
        Learn more about your rights by reading Human Rights Articles.
      </p>
    </div>
  </Section>

  <div class="bg-white px-5 md:px-36 lg:px-64 flex flex-col md:flex-row pb-10">
    <div class="w-full md:w-1/2 flex items-baseline">
      <h2 class="text-3xl font-bold text-gray-800">Human Rights Articles</h2>
      <div class="h-1 w-32 bg-green-700 ml-1"></div>
    </div>

    <div class="w-full md:w-1/2 mt-4">
      <Accordion title="Article 1">
        <div class="text-justify text-gray-700">
          <h4 class="text-xl">Universal Declaration of Human Rights</h4>
          <p class="mt-2">
            The rights of all people worldwide are protected by three documents
            created by the United Nations: the Universal Declaration of Human
            Rights,(UDHR)the International Covenant on Economic, Social and
            Cultural Rights(ICESCR)and the International Covenant on Civil and
            Political Rights(ICCPR)Together, these three documents form the
            International Bill of Human Rights.
          </p>

          <br />
          <p>
            Malawi has signed and ratified all three documents, which means that
            it is legally bound by the provisions of the two Covenants. Other
            convents to which Malawi is a signatory are the ones aimed at
            promoting the rights of women, The International Covenant on the
            Elimination of All forms of Discrimination against Women(CEDAW) and
            the Convention on the Rights of the Child(CRC) Because Africa has
            specific issues and challenges.
          </p>

          <br />
          <p>
            The African Union has created its own document, the African Charter
            on Human and People's Rights(also known as the Banjul Charter). The
            charter functions similarly to an international treaty, and the
            African Union has set up the African Court on Human and Peoples'
            Rights to uphold its provisions.
          </p>

          <br />
          <p>
            International law is complicated and very difficult to enforce, so
            it is important for countries to provide for human rights in their
            own laws. The Constitution of Malawi is the highest law in the
            country; it governs the way in which all other laws are made, and it
            prevails if there is a disagreement between it and another legal
            instrument. Certain parts of the constitution can only be changed by
            referendum- which means the people get a chance to vote. This is an
            important protection against excessive or irresponsible use of
            government power.
          </p>

          <br />
          <p>
            Chapter five of the Constitution details your constitutional rights.
            There are some inconsistencies between the international laws on
            human rights and the Constitution. Some of these are rights that
            have been left out of the constitution because Malawi does not yet
            have the resources to achieve them. Others are limitations and
            reservations placed on your constitutional rights in the interests
            of making it easier to govern.
          </p>

          <br />
          <p>
            These are limitations that the Malawian government has decided to
            impose, and in some case, restrict the ability of Malawians to
            exercise their human rights. Human rights law in Malawi is further
            complicated by customary law, which is given status by the
            Constitution. Customary law is the body of law created by cultural
            habit and tradition, and is often unwritten; Organizations like the
            Rights Institute for Social Empowerment-RISE work to encourage
            governments to bring their laws into line with the International
            Bill of Human Rights for the betterment of all people in Malawi.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 3">
        <div class="text-justify text-gray-700">
          <h4 class="text-xl">
            Everyone has the right to life, liberty and security of person
          </h4>
          <p class="mt-2">
            No one should be killed, for any reason. Also, everyone has the
            right to be free and to be safe from harm.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 4">
        <div class="text-justify text-gray-700">
          <h4 class="text-xl">
            No one shall be held in slavery or servitude; slavery and the slave
            trade shall be prohibited in all their forms
          </h4>
          <p class="mt-2">
            Nobody has the right to treat you as his or her slave and you should
            not make anyone your slave. Therefore, a person can never be someone
            else's property.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 5">
        <div class="text-justify text-gray-700">
          <h4 class="text-xl">
            No one shall be subjected to torture or to cruel, inhuman or
            degrading treatment or punishment
          </h4>
          <p class="mt-2">
            No one is allowed to torture or to hurt someone badly, not even as a
            punishment. This means at school, your teachers are not allowed to
            viciously or excessively use the cane against you.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 7">
        <div class="text-justify text-gray-700">
          <h4 class="text-xl">
            All are equal before the law and are entitled without any
            discrimination to equal protection of the law. All are entitled to
            equal protection against any discrimination in violation of this
            Declaration and against any incitement to such discrimination
          </h4>
          <p class="mt-2">
            This article means that the law is the same for everyone. It should
            be applied in the same way to all. If someone is not treated
            equally, he or she should be protected by the law.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 16">
        <div class="text-justify text-gray-700">
          <p class="">
            Men and women of full age, without any limitation due to race,
            nationality or religion, have the right to marry and to found a
            family. They are entitled to equal rights as to marriage, during
            marriage and at its dissolution.
          </p>

          <br />
          <p class="">
            Marriage shall be entered into only with the free and full consent
            of the intending spouses.
          </p>

          <br />
          <p>
            The family is the natural and fundamental group unit of society and
            is entitled to protection by society and the State. All men and
            women 18 and over have the right to be married AND divorced. But,
            you cannot be FORCED to be married - you should only be married if
            that is what you want.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 18">
        <div class="text-justify text-gray-700">
          <h4 class="text-xl">
            Everyone has the right to freedom of thought, conscience and
            religion
          </h4>
          <p class="mt-2">
            This right includes freedom to change his religion or belief, and
            freedom, either alone or in community with others and in public or
            private, to manifest his religion or belief in teaching, practice,
            worship and observance. Everyone has the right to choose your
            religion [or philosophy]. Everyone also has the right to profess
            your religion freely, to change it, and to practise it either on
            your own or with other people.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 19">
        <div class="text-justify text-gray-700">
          <h4 class="text-xl">
            Everyone has the right to freedom of opinion and expression
          </h4>
          <p class="mt-2">
            This right includes freedom to hold opinions without interference
            and to seek, receive and impart information and ideas through any
            media and regardless of frontiers. Everyone has the right to make up
            their own mind, to think what they like, to say what they think, and
            to share ideas with other people - anywhere in the world.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 25">
        <div class="text-justify text-gray-700">
          <!-- <h4 class="text-xl">
            Everyone has the right to freedom of opinion and expression
          </h4> -->
          <p class="">
            Everyone has the right to a standard of living adequate for the
            health and well-being of himself and of his family, including food,
            clothing, and housing and medical care and necessary social
            services, and the right to security in the event of unemployment,
            sickness, disability, widowhood, old age or other lack of livelihood
            in circumstances beyond his control.
          </p>

          <br />
          <p>
            Motherhood and childhood are entitled to special care and
            assistance. All children, whether born in or out of wedlock, shall
            enjoy the same social protection.
          </p>
        </div>
      </Accordion>

      <br />
      <Accordion title="Article 26">
        <div class="text-justify text-gray-700">
          <!-- <h4 class="text-xl">
            Everyone has the right to freedom of opinion and expression
          </h4> -->
          <p class="">
            Everyone has the right to education. Education shall be free, at
            least in the elementary and fundamental stages. Elementary education
            shall be compulsory. Technical and professional education shall be
            made generally available and higher education shall be equally
            accessible to all on the basis of merit.
          </p>

          <br />
          <p>
            Education shall be directed to the full development of the human
            personality and to the strengthening of respect for human rights and
            fundamental freedoms. It shall promote understanding, tolerance and
            friendship among all nations, racial or religious groups, and shall
            further the activities of the United Nations for the maintenance of
            peace.
          </p>

          <br>
          <p>
              Parents have a prior right to choose the kind of education that shall be given to their children.
          </p>
        </div>
      </Accordion>
    </div>
  </div>
</template>

<script>
import Section from "@/components/Section.vue";
import Accordion from "@/components/Accordion.vue";

export default {
  name: "Articles",
  components: {
    Section,
    Accordion,
  },
};
</script>

<style>
</style>